import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'

export const Me = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "mac.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent>
          <Typography variant="h2" className={classes.heading}>
            Hi, my name is
          </Typography>
          <Typography variant="h1" className={classes.heading}>
            Jens Hansen
          </Typography>
          <Typography>
            I'm a Software Engineer based in Berlin, Germany with focus on
            building high-quality frontend applications.
          </Typography>
        </CardContent>
      </div>
      <Hidden only={'xs'}>
        <div className={classes.image}>
          <Img fluid={data.file.childImageSharp.fluid} />
        </div>
      </Hidden>
    </Card>
  )
}

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  heading: {
    marginBottom: '10px',
  },
  details: {
    flexDirection: 'column',
  },
  image: {
    minWidth: '150px',
    marginLeft: 'auto',
  },
})
