import React from 'react'

import Grid from '@material-ui/core/Grid'

import { Me } from '../me'
import { Spring } from '../spring'

export const Introduction = () => {
  return (
    <Grid spacing={2} container>
      <Grid md={6} xs={12} item>
        <Me />
      </Grid>
      <Grid md={6} xs={12} item>
        <Spring />
      </Grid>
    </Grid>
  )
}
