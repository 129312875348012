import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import MuiAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Link from '@material-ui/core/Link'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { DarkLightToggle } from './dark-light-toggle'
import { GithubLogo } from './github-logo'

export const AppBar = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "comic-me.png" }) {
        childImageSharp {
          fixed(width: 69, height: 75) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const classes = useStyles()
  return (
    <MuiAppBar color={'secondary'} position="static">
      <Toolbar>
        <div className={classes.main}>
          <Link href={'/'}>
            <Img fixed={data.file.childImageSharp.fixed} />
          </Link>
        </div>
        <DarkLightToggle />
        <GithubLogo />
      </Toolbar>
    </MuiAppBar>
  )
}

const useStyles = makeStyles(() => ({
  main: {
    flexGrow: 1,
    display: 'flex',
  },
}))
