import React, { useContext } from 'react'

import IconButton from '@material-ui/core/IconButton'
import DarkIcon from '@material-ui/icons/Brightness4'
import LightIcon from '@material-ui/icons/Brightness7'

import { DarkLightContext } from '../theme'

export const DarkLightToggle = () => {
  const { isDarkMode, toggleDarkMode } = useContext(DarkLightContext)
  return (
    <IconButton onClick={toggleDarkMode}>
      {isDarkMode ? <LightIcon /> : <DarkIcon />}
    </IconButton>
  )
}
