import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

export const Spring = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "spring.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <div className={classes.image}>
        <Img fluid={data.file.childImageSharp.fluid} />
      </div>
      <div>
        <CardContent>
          <Typography>
            Currently I'm working for{' '}
            <a
              href={'https://www.axelspringer.de'}
              rel={'noopener noreferrer nofollow'}
              target={'_blank'}
            >
              Axel Springer
            </a>
            . We are driving business and technology for Axel Springer's
            national media brands. We are responsible for more than 60 digital
            products, more than 25 Brands and two of Europe’s biggest news
            sites.
          </Typography>
        </CardContent>
      </div>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
  },
  heading: {
    marginBottom: '10px',
  },
  image: {
    marginTop: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '142px',
  },
}))
