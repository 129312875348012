import React from 'react'

import { Helmet } from 'react-helmet'

import {
  makeStyles,
  createStyles,
  Theme as MuiTheme,
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import { Theme } from '../components/theme'
import { AppBar } from '../components/app-bar'
import { Introduction } from '../components/introduction'
import { Publications } from '../components/publications'
import { PersonalProjects } from '../components/personal-projects'

const IndexPage = () => {
  const classes = useStyles()
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Jens Hansen - Software Engineer based in Berlin</title>
        <link rel="canonical" href="http://www.jenshansen.org" />
        <meta
          name="description"
          content="Jens Hansen is a passionate Software Engineer living in Berlin, Germany"
        />
      </Helmet>
      <Theme>
        <CssBaseline />
        <AppBar />
        <Container className={classes.container}>
          <Grid direction={'column'} spacing={2} container>
            <Grid item>
              <Introduction />
            </Grid>
            <Grid item>
              <Publications />
            </Grid>
            {/* <Grid item>
              <PersonalProjects />
            </Grid> */}
          </Grid>
        </Container>
      </Theme>
    </>
  )
}

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2),
    },
  })
)

export default IndexPage
