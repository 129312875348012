import React, { createContext, useContext, useState } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

export const MODE = {
  DARK: 'dark',
  LIGHT: 'light',
}

export const DarkLightContext = createContext({
  isDarkMode: false,
  toggleDarkMode: () => {},
})

const ThemeComponent = ({ children }) => {
  const { isDarkMode } = useContext(DarkLightContext)
  const theme = createMuiTheme({
    palette: {
      type: isDarkMode ? 'dark' : 'light',
      secondary: {
        main: isDarkMode ? '#424242' : '#FFF',
      },
    },
    typography: {
      h1: {
        fontSize: '2rem',
      },
      h2: {
        fontSize: '1.2rem',
      },
    },
  })

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export const Theme = ({ children }) => {
  const localStorageDarkMode =
    typeof window === 'undefined'
      ? null
      : window.localStorage.getItem('darkMode') === 'true'
  const mediaQueryDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const prefersDarkMode =
    localStorageDarkMode !== null ? localStorageDarkMode : mediaQueryDarkMode
  const [isDarkMode, setIsDarkMode] = useState(prefersDarkMode)

  const contextValue = {
    isDarkMode,
    toggleDarkMode: () => {
      if (typeof window !== 'undefined') {
        window.localStorage.setItem('darkMode', !isDarkMode ? 'true' : 'false')
      }
      setIsDarkMode((isDarkMode) => !isDarkMode)
    },
  }

  return (
    <DarkLightContext.Provider value={contextValue}>
      <ThemeComponent>{children}</ThemeComponent>
    </DarkLightContext.Provider>
  )
}
