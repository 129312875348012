import React, { useState } from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import grey from '@material-ui/core/colors/grey'

const MEDIUM_CDN = 'https://cdn-images-1.medium.com/max/400'
const MEDIUM_URL = 'https://medium.com'

export const Publications = () => {
  const { allMediumPost } = useStaticQuery(graphql`
    query {
      allMediumPost {
        edges {
          node {
            title
            virtuals {
              readingTime
              previewImage {
                imageId
              }
            }
            content {
              subtitle
            }
            uniqueSlug
            createdAt(formatString: "MMM YYYY")
            author {
              username
            }
          }
        }
      }
    }
  `)

  const classes = useStyles()
  const [raisedCard, setRaisedCard] = useState(null)

  return (
    <div className={classes.root}>
      <Typography className={classes.header} variant={'h2'}>
        Publications
      </Typography>
      <Grid container>
        {allMediumPost.edges.map(({ node }, index) => {
          return (
            <Grid key={`publication-${index}`} item>
              <a
                href={`${MEDIUM_URL}/@${node.author.username}/${node.uniqueSlug}`}
                target="_blank"
                rel={'noopener noreferrer nofollow'}
              >
                <Card
                  className={classes.card}
                  raised={raisedCard === index}
                  onMouseEnter={() => setRaisedCard(index)}
                  onMouseLeave={() => setRaisedCard(null)}
                >
                  <CardContent>
                    <Typography
                      className={classes.cardTitle}
                      component={'h3'}
                      variant={'h2'}
                    >
                      {node.title}
                    </Typography>
                  </CardContent>
                  <CardMedia
                    component="img"
                    alt={node.title}
                    image={`${MEDIUM_CDN}/${node.virtuals.previewImage.imageId}`}
                    title={node.title}
                  />
                  <CardContent>
                    <Typography>{node.content.subtitle}</Typography>
                  </CardContent>
                  <Typography className={classes.cardFooter}>
                    {node.createdAt} - {Math.round(node.virtuals.readingTime)}{' '}
                    Min
                  </Typography>
                </Card>
              </a>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  header: {
    fontSize: '2.2rem',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'inline-box',
    borderBottom: `5px solid ${grey['300']}`,
  },
  card: {
    width: '350px',
  },
  cardTitle: {
    fontSize: '1rem',
    fontWeight: 700,
  },
  cardFooter: {
    color: '#000',
    float: 'right',
    display: 'inline-box',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    backgroundColor: grey['300'],
    clipPath: 'polygon(25px 0%, 100% 0%, 100% 100%, 0% 100%)',
    '-webkit-clip-path': 'polygon(25px 0%, 100% 0%, 100% 100%, 0% 100%)',
  },
}))
